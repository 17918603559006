module.exports = {
	tba: {
		Successqinggua: 'Successfully cleared the pending order',
		failqinggua: 'Failed to clear pending orders',
		Qingliguadan: 'Clean up the hanging list',
		wave_f_coin: 'Wave Coin',
		bindEmailSuccess: 'Bind mailbox successfully',
		quedingbangding: 'Determine binding',
		qingshuruyouxiang: 'Please enter email address',
		qingshuruyouxiangzhanghao: 'Please enter email account',
		duo: 'Long',
		kong: 'Short',
		allchecked: 'Select all',
		bian1: 'BINANCE',
		huobi1: 'HUOBI',
		okex1: 'OKEX',
		zhiyingjiage: 'Profit stop price',
		manual_f_long: 'Manual Long',
		manual_f_short: 'Manual Short',
		beishu: 'multiple',
		wangge_x: 'De-Martin',
		wave_f_long: 'waveHedgeL',
		wave_f_short: 'waveHedgeS',
		look_all: 'View all',
		weighted_share_reward_raise: 'Share Reward',
		yuerbuzu: 'Insufficient balance, please recharge first!',
		shangxueyuan: 'Business School',
		insufficient_gas: 'Insufficient fuel cost',
		not_valid_membership: 'User is not a valid member',
		wrong_params: 'Parameter error',
		failed_to_save: 'Failed to save settings',
		check_params: 'Please check the parameters',
		empty_bot_id: 'Robot ID is empty',
		wrong_bot_id: 'Robot ID error',
		server_error: 'Server exception',
		zhisunjiage: 'stop price',
		dangqianzijin: 'Current funds',
		dangqianchicang: 'Current position',
		queshaojiaoyimiyao: 'Missing exchange private key, bind now?',
		lijibangdin: 'Bind now',
		jiaoyileixin: 'Transaction type',
		im_teacher: 'I am a teacher',
		add_lianghua: 'Add quantification',
		ljyjsr: 'Accumulated commission income',
		xianshimingcheng: 'Display name',
		xuanzhejiaoyisuo: 'Choose an exchange',
		jiaoyifangshi: 'transaction mode',
		zhanghuzongzichan: 'Total assets of account',
		weikaiqi: 'Not open',
		jinxinzhong: 'have in hand',
		shenhezhong: 'Under review',
		yichehui: 'Withdrawn',
		yijujue: 'Rejected',
		xiugaixinxi: 'Modify information',
		kaiqi: 'open',
		tingzhilianghua: 'Stop quantifying',
		chehuishenhe: 'Withdraw audit',
		quanbutingzhi: 'Stop all',
		quedingyaokaiqilianghuama: 'Are you sure you want to turn on quantization？',
		queding: 'determine',
		zanshibuyao: 'Not for the time being',
		quedingyaotingzhilianghuama: 'Are you sure you want to stop quantifying？',
		tingzhihoubunengjixu: 'After stopping, you cannot continue to bring orders',
		quedingyaochehuishenqingma: 'Are you sure you want to withdraw the application？',
		quedingyaotingzhiall: 'Are you sure you want to stop all quantization？',
		success_che: 'Withdrawal successful',
		success_stop: 'Stop success',
		success_open: 'Open successfully',
		tuoguanxiangqing: 'Escrow details',
		putongVIP: 'Ordinary members',
		xiugaichushituoguanzijin: 'Modify initial custody funds',
		hdsy: 'Gain revenue',
		syfd: 'Income range',
		mrsy: 'Daily revenue',
		xiugaichenggong: 'Modified successfully',
		my_tuoguanxiangqing: 'My trusteeship details',
		tuoguancishu: 'Hosting times',
		daidanjiner: 'Amount with bill',
		nidezhanghaohaiweibangding: 'Your account has not been bound yet',
		pingtaizanwufashiyong: 'The platform is temporarily unable to use the hosting function',
		qubangding: 'Debinding',
		xiugailianghua: 'Modification quantification',
		qingshuruxianshimingcheng: 'Please enter the display name',
		shoufeibili: 'Charge ratio',
		qingshurushoufeibili: 'Please input the charge proportion',
		zhanghuzongzichan: 'Total assets of account',
		qingshuruzhanghuzongzichan: 'Please enter the total assets of the account',
		tijioashenqing: 'Submit application',
		shoufeilvbuhefa: 'Illegal charge rate',
		
		fxtishi: 'Risk statement',
		agree: 'agree',
		refuse: 'refuse',
		Contract: 'Contract',
		xiugaifangxiang: 'Direction changed,Current postion will be cleanup',
		celue: 'strategy',
		zuoduo: 'Long',
		zuokong: 'Short',
		zuodanfangxiang: 'Do one direction',
		chengweilaoshi: 'Become a teacher',
		woyaotuoguan: 'I want to trust',
		wodetuoguan: 'My trusteeship',
		gensuizhe: 'Followers',
		onedayshouyi: '24 hourly revenue',
		fenchengbili: 'Share ratio',
		zongdaidanrenshu: 'Total number of single person',
		nianhuashouyi: 'Annualized income',
		jinrishouyi: 'Today earnings USDT',
		leijishouyi: 'Cumulative revenue USDT',
		zhengzaijinxin: 'underway',
		yijieshu: 'Its over',
		chushituoguanzijin: 'Initial escrow funds',
		huodeshouyi: 'Gain revenue USDT',
		tuoguanshijian: 'Hosting time',
		xiugaizijin: 'Modify funds',
		wodeleijishouyi: 'My cumulative income',
		wodetuoguancishu: 'My hosting times',
		xiangqing: 'details',
		chushizijin: 'Initial funding',
		shouyilv: 'Rate of return',
		ti_tingzhi: 'After stopping hosting, you will no longer follow the teacher!',
		tuoguanzijin: 'Escrow funds',
		zuidakeyongyue: 'Maximum available balance',
		qingshuru: 'Please input',
		quanbutuoguan: 'Full escrow',
		querentuoguan: 'Confirm escrow',
		cangwei: 'Position',
		jinsantianshouyi: 'Daily chemical income in recent three days',
		yuqinianhuashouyilv: 'Expected annualized rate of return',
		zhengzaidaidanrenshu: 'Number of people taking orders',
		global_reward_raise: 'Global Reward',
		trusteeship_brokerage_reduce: 'Trusteeship Reward',
		trusteeship_brokerage_add: 'Trusteeship Reward',
		zhangfudie: 'Up or down',
		xianhuojiaoyi: 'Spot trading',
		heyuejiaoyi: 'Contract',
		kaifazhong: 'Under development',
		new_price: 'Latest price',
		piliangtankuang: 'Only for all currencies that have been checked',
		transfer_accounts: 'Transfer successful',
		refund: 'Refund',
		xianhuo_gas: 'Gas',
		deposit: 'Deposit',
		team_reward: 'Team Reward',
		direct_promote: 'Direct Reward',
		indirect_promote: 'Indirect Reward',
		flat_level_reward_raise: 'Flat Reward',
		buy_active_code: 'Buy VIP',
		withdraw: 'Withdraw',
		transfer: 'Transfer',
		transfer_in: 'In',
		transfer_out: 'Out',
		system_deposit: 'System Deposit',
		frozen_asset: 'Frozen',
		transfer_out_fee: 'Transfer Fee',
		withdraw_deny: 'Withdraw Deny',
		Delete: 'Delete',
		sure_to_delete: 'Are you sure to delete?',
		delete_ok: 'Successfully deleted！',
		delete_no: 'Deletion canceled！',
		SetAPI: 'set up API',
		DeleteAPI: 'delete API',
		bucangbeishu: 'Multiple',
		wave_trend: 'Wave trend',
		marting: 'Marting',
		wangge_f: 'Marting',
		bottom: 'Bottom track',
		wave: 'Grid Wave',
		wave_f: 'Grid Wave',
		Single: 'Single',
		Continuous: 'Continuous',
		APIAuthorization: 'API',
		// -----20210414 start---------------------------------------------------
		accountnumber: 'Account',
		article: 'Article',
		Notice: 'Notice',
		SuccessfulPurchase: 'Buy success！',
		AvailableBalance: 'available balance',
		Processing: 'Processing...',
		Stoping: 'Stoping...',
		Clearanceing: 'Clearancing...',
		GetParameters: 'Get the parameters...',
		qidong: 'Starting...',
		qidongsuccess: 'Start successfully',
		FailedAcquireRobot: 'Failed to acquire Robot',
		Loading: 'Loading...',
		SaveParametersSucceeded: 'Save parameters succeeded！',
		SaveParametersFailed: 'Save parameters failed！',
		SaveStartSuccessfully: 'Save and start successfully！',
		CannotEmpty: 'Parameter cannot be empty！',
		robot: 'robot',
		StopSuccess: 'Stop Success',
		PleaseSetTogetInitializationParameters: 'Please click Set first to get initialization parameters',
		OnceMaxAction20: '20 transaction pairs cannot be operated at one time',
		SelectOneMore: 'Please select at least one transaction pair',
		successNumber: 'Number of success',
		failNumber: 'Number of failures',
		SaveingParametersbatch: 'Save parameters in batch...',
		errorWithdrawAmount: 'The amount of money withdrawn is not correct',
		// -----20210414 end----
		// -----20210413 start----
		SelectPosition: 'Your Position',
		Profits: '%Profit',
		UserHostingAgreement: '<Hosting Agreement>',
		TrusteeshipRiskyShouldBeCaution: 'Caution Risky in Trusteeship',
		ServiceRights: 'Service rights',
		enteryourMobilenumber: 'Please fill in your mobile number！',
		PleaseCheckUserAgreement: 'Please check "user agreement"',
		RegistrationSuccessfulReturntoLogin: 'Registration successful! Please return to login page to login！',
		PleaseEnterYourUsername: 'enter username',
		AnnouncementDetails: 'Announcement details',
		PasswordLogin: 'Account',
		VerificationCodeLogin: 'Mobile',
		// ------20210413[end]-----
		// -----20200409 start----
		APIset: 'Set APIKey',
		codeHasSend: 'Verification code sent to：',
		enterAPIKey: 'Please input API KEY',
		enterSecretKey: 'Please input Secret KEY',
		enterPassphrase: 'Please input Passphrase',
		setWord1: 'Get',
		setWord1_2: 'API KEY',
		setWord2: 'Enter or copy and paste your',
		setWord3: 'Open an account immediately',
		walletRecord: 'Wallet records',
		fuelFee: 'Fuel ',
		totalReward: 'Quantifying total revenue',
		today: 'today：',
		total: 'total：',
		addUp: 'Total',
		toBeVIP: 'VIP',
		setupMarting: 'Martin setup',
		Onceloop: 'Single',
		ci: 'Times',
		cycle: 'Time Range',
		extentContinuousDecline: 'Continuous Decline',
		MemberClub: 'VIP',
		deadline: 'Expired',
		youarenotVip: 'You are not a member yet',
		handlingMembership: 'Membership',
		yes: 'OK',
		chongbiAddress: 'Charging address',
		choosebi: "Select currency",
		reciceMoneyAddress: 'Collection address',
		reciveAccount: 'Receiving account number',
		reciveAccountName: 'Receiving account name',
		tibiConfirmInfo: 'Confirmation information',
		RiskStatement: 'Risk statement',
		riskTipsText: {
			one: '1、Please check the address information carefully. Once the withdrawal is completed, the assets will not be recovered;',
			two: '2、Please be alert to any illegal activities such as pyramid selling, illegal fund-raising, fraud, etc., and guard against fraud such as "moving bricks to arbitrage" and "trading with orders", so as to avoid causing property losses;',
			three: '3、Any investment claimed to be  cooperation is fraud. You can click  official verification channel to check whether it is  official channel;',
			four: '4、Digital asset investment is a high-risk behavior. Please pay attention to identify investment projects such as pyramid selling currency and Shanzhai currency;',
			five: '5、If you have any questions, please contact customer service in the help center.',
		},
		tibiAddressError: 'Wrong withdrawal address',
		checkNetwork: 'Please check the network',
		SecurityVerification: 'Security verification',
		verificationCode: 'Mobile phone verification code',
		PasswordInput: 'Password input',
		VerificationCodeSentSuccess: 'Verification code sent successfully',
		changeLoginPass: 'Change login password',
		setTransferPass: 'Set transaction password',
		setDefaultExchange: 'Set default exchange',
		inputLoginPwd: 'Please enter the login password',
		changePwd: 'Change Password',
		copyVersion: 'Version number',
		weiXin: 'WeChat',
		trusteeship: 'Hosting',
		trusteeship_close: 'Stop hosting',
		trusteeshipClose: 'Do you want to close the current package?',
		trusteeshipClose_yes: 'Yes',
		trusteeshipClose_no: 'No',
		trusteeshipClose_success: 'Closed package successfully!',
		trusteeshipOpen_success: 'The package was successfully opened!',
		trusteeshipPlease_check: 'Please check the user hosting agreement first!',
		uniswap: 'uniswap',
		dealNow: 'Deal now',
		cao_num: 'Excess of position',
		SelloutQuantity: 'Sales volume:',
		noRobot: 'No robots',
		startNow: 'Open now',
		oneButtonReplenishment: 'One key replenishment(USDT)',
		ReplenishmentQuantity: 'Replenishment quantity:',
		MartinDetails: 'Martin details',
		BottomTrackingDetails: 'Bottom tracking details',
		stopRobotFirst: 'Please stop the robot first',
		SuccessReplenish: 'Successful replenishment',
		SuccessSellout: 'Sold successfully',
		failSellout: 'Sell failure',
		failReplenish: 'Failed to replenish position',
		SuccessClearance: 'Successful clearance',
		failClearance: 'Clearance failure',
		OrderStatus: 'Order status',
		helpGuide: 'Help',
		resetPassword: 'Reset password',
		nextStep: 'Next',
		enterMobileNumber: 'Please enter your mobile phone number',
		EnterReceivedVerificationCode: 'Enter the received verification code',
		Resend: 'Resend',
		// -----20210414 end---------------------------------------------------
		historyRecord: 'Record',
		descriPtion: 'Description',
		filterType: 'Filter Type',
		MyProfit: 'My Profit',
		todayProfit: 'Daily Return',
		AccumulatedProfit: 'Cumulative Return',
		order: 'Order',
		date: 'Date',
		running: 'Running',
		stop: 'Stopped',
		all: 'All',
		manualBuchang: 'Manually Cover',
		manualMaichu: 'Sell manually',
		activated: 'Activated',
		inactivated: 'Inactivated',
		Directpushtotalrevenue: 'Total referral benefits',
		Directlypushtodaysearnings: 'Daily referral benefits',
		Todaysearnings: 'Daily revenue',
		Teamtodaysearnings: 'Daily team profit',
		Totalteamrevenue: 'Total team profit',
		DirectMembership: 'Direct Referral Membership',
		DirectMembershiplist: 'Direct push member list',
		Numberofteam: 'Number of Team',
		sequence: 'Order',
		activation: 'Active',
		Directpush: 'Direct',// Referral
		Team: 'Team',
		Totalrevenue: 'Total revenue',
		Earningsrecord: 'Earnings record',//=======?
		Orderid: 'OrderId',
		Tradingpair: 'Trading pair',
		Cumulativeincome: 'Cumulative income',
		Cumulativeincomedaily: 'Cumulative income (daily)',
		Buyin: 'Buy',
		TransactionRecord: 'Transaction&Logs',
		Sell: 'Sell',
		Thenumberoftransactions: 'Amount',
		Averagetransactionprice: 'Average price',
		transactionfee: 'Transaction',
		Handlingfee: 'Handling fee',
		NumberofCalls: 'Call times',
		Log: 'Log',
		OperationID: 'Operation ID',
		news: 'News',
		Popular: 'Popular',
		Pleaseentercurrency: 'Enter Currency',
		searchfor: 'Search',
		Batchclearance: 'Batch closeout',
		Bulkreplenishment: 'Batch margin call',
		Batchstart: 'Batch start',
		MotherboardMainarea: 'Main Zone',
		Stop: 'Stop',
		Increase: 'Increase',
		InnovationZone: 'NEXT',
		parameter: 'Parameter',
		Cycleway: 'Cycle Way',
		Openingamount: 'Entering value',
		Takeprofitmargin: 'Profit margin',
		TakeProfitCallback: 'Profit Correction',
		Margin: 'Margin',
		Callback: 'Retracement',
		Whethertopushflat: 'Whether to push flat',//==========？
		Saveandstart: 'Save and start',
		Securitycenter: 'Security',
		tool: 'Tool',
		Helpcenter: 'Support',
		aboutus: 'About',
		dropout: 'Log out',
		Helpdescription: 'Help description',
		HelloIamXiaoRcanIhelpyouYoucanclickonthecorrespondingquestiontounderstandtheworkingprincipleofRainsintelligentquantitativerobot: 'Hello, I am Xiao R. How can I help you? Click on the corresponding question to learn more about Rain\'s intelligent quantitative robot.',
		NoviceOperationGuide: 'Guide for beginners',
		Rainsstrategyparameterfunctionsettinginstructions: 'Instructions of Rain\'s parameter function setting',
		Rainsrecommendationrewardmechanism: 'Rain\'s recommendation and reward system',
		Aboutrates: 'About rates',
		Aboutdepositandwithdrawalfees: 'About deposit and withdrawal fees',
		Chainname: 'Chain',
		PleasedonotdepositanynonTRC20USDTassetstotheaboveaddressotherwisetheassetswillnotberetrieved: 'Please do not deposit any non-TRC20_USDT assets to the address above, otherwise the assets will not be retrieved',
		Quantity: 'Quantity',
		Minimumwithdrawalamount10: 'Minimum withdrawal amount 10',
		zuyi: 'Note: the minimum withdrawal amount of the platform is',
		shouxufei: 'The withdrawal fee is',
		shouxufei_danwei: 'U each time',
		shouxufei_danwei2: 'each time',
		Arrivalquantity: 'Amount received',
		Seagrid: 'Sea grid',//=============
		Spot: 'Spot',
		Continuousloop: 'Continuous',
		begin: 'Begin',
		unconfig: 'Unconfig',
		DealFor: 'Trading pair',
		exchange: 'Exchange',
		Profit: 'Profit',
		Activation1code: 'Activation code (Used)',
		MyActivationCode: 'My Activation Code',
		PurchaseQuantity: 'Purchase amount',
		payment: 'payment',
		PurchaseActivationCode: 'Purchase activation code',
		BuyNow: 'Buy Now',
		setup: 'Save',
		start_up: 'Start',
		stop_it: 'Stop',
		assets: 'Assets',
		ChainType: 'Chain Type',
		Receivingaddress: 'Receiving address',
		copy: 'copy',
		explain: 'Please do not recharge any non usdt-trc20 assets to the address above, otherwise the assets will not be returned',
		cancel: 'Cancel',
		title: 'Strategy details',
		PositionAmount: 'Position',//=？
		AveragePositionPrice: 'Avg Price',
		Numberofpositionsfilled: 'Covered',//==========补仓单词选择存疑margin call更多表示追加保证金
		PositionQuantity: 'Quantity',//=？
		CurrentPrice: 'Last Price',
		Up: 'Rate',
		StrategyRelated: 'Strategy Related',
		OpeningAmount: 'Open Amount',
		Replenishmenttimes: 'Covered',//=问题同上 是否使用covered times 还是 margin call
		Profitmargin: 'Take Profit',
		zhiyingfudu: 'Sold',
		zhiyingcishu: 'Sold',
		OneProfitmargin: 'Profit margin per order',
		Suofanglv: 'Width scaling ratio',
		Zuidabucangcishu: 'Maximum replenishment times',
		Wanggekuandu: 'Grid width',
		Bucangjinersuofanglv: 'Zoom rate of replenishment amount',
		gangganbeishu: 'Leverage ratio',
		Profitstopcallback: 'Profit Correction',
		Marginofreplenishment: 'Margin Cover',//=问题同118
		Makeupcallback: 'Cover Correction',//=问题同118
		Horizontalpushornot: 'Double Push',//=什么是平推？
		pingtuifudu: 'Horizontal thrust range',
		shoucibucang: 'First replenishment',
		di2cibucang: 'The second replenishment',
		di3cibucang: 'The third replenishment',
		di4cibucang: 'The fourth replenishment',
		di5cibucang: 'The fifth replenishment',
		di6cibucang: 'The sixth replenishment',
		di7cibucang: 'The seventh replenishment',
		di8cibucang: 'The 8th replenishment',
		di9cibucang: 'The 9th replenishment',
		di10cibucang: 'The 10th replenishment',
		di11cibucang: 'The 11th replenishment',
		di12cibucang: 'The 12th replenishment',
		di13cibucang: 'The 13th replenishment',
		Cyclesetting: 'Cycle setting',
		DeclineRange: 'Decline range',
		Policyoperation: 'Strategy operation',
		Policysettings: 'Strategy settings',
		Clearingandselling: 'Clean Position',
		Bottomloop: 'Bottom loop=？',
		WelcometoRainbow: '',
		Passwordlogin: 'Account',
		SMSlogin: 'Mobile',
		Wavegrid: 'Wave grid',
		All: 'All',
		Position: 'Positions',
		Floating: 'Profit',
		Thepositionhasbeencovered2times: 'The position has been covered 2 times',
		Batchsettings: 'Batch settings',
		Batch_stop: 'Batch stop',
		Bulk_clearing: 'Batch clearance',
		Usedusdt: 'Used usdt',
		Usdtavailable: 'Available',
		AccountassetsUSDT: 'Account assets (USDT)',
		Available: 'Available',
		ImmediatelyactivatetheRainbowintelligentquantitativerobot: 'Immediately activate the  intelligent quantitative robot',
		LogintoagreeUserAgreement: 'Login to agree, "User Agreement"',
		Obtain: 'Obtain',
		savefail: "save failed",
		home: "Home",
		vcode: "Verification code",
		email: 'E-mail',
		save: "Save",
		YouNeedEmail: "Please set an E-mail to continue.",
		currency: 'Currency',
		strategy_setup: 'Strategy Setup',
		defaultExchange: 'Default Exchange',
		defaultLang: 'Default Language',
		defaultVcode: 'Default Verification Code',
		vcode_title: 'Set Verification Code',
		sms: 'Short Message',
		vcode_msg: 'A verification code is required for withdrawals and transactions, please select a way to send default verification code.',
		savesucc: 'Save Successful',
		defaultExchange_msg: 'Select your favor exchange',
		huobi: 'Huobi',
		bian: 'Biance',
		en: 'English',
		zh: 'Chinese',
		lang: 'Set Language',
		lang_msg: 'Please select a default interface and an operating language',
		checkversion: 'Check version',
		currency: 'Transaction pair',
		strategy: 'Strategy',
		wangge: 'Marting',
		wangge2: 'Bottom track',
		wangge3: 'Wave grid',
		wangge4: 'Wave trend',
		wangge_m: 'Wave trend',
		quant: 'Trading',//= Settings参数设置之类的可能合适一点
		trade: 'Trading',
		my: 'Mine',// =指代的什么？
		contract: 'Contract',
		community_profit: '指代不明 指代的是团队收入 还是团队收益',
		my_profit: 'My Profit',
		begin_quant: 'Start',
		quant_welcome: 'Welcome to use TBA Quant System',
		follow_setup: 'Follow Setup',
		follow_methods: 'Follow_methods',
		follow_all: 'Follow all tokens',
		token_select_setting: 'token settings',
		asset_scale: 'Asset scale',//=净值缩放什么意思
		select_trade_strategy: 'Select Trade Strategy',
		IagreetotheUserAgreementandPrivacyPolicy: 'I agree to the "User Agreement" and "Privacy Policy"',
		aritcle: 'Information',
		VIPmember: 'VIP',
		invitefriends: ' Invite',
		Leaderboard: 'Leaderboard',//======?
		myteam: 'Team',
		Bill: 'BILL',
		transaction: 'Transaction',
		Huobi: 'Huobi',
		Binance: 'Binance',
		OKEx: 'OKEx',
		USDTcontract: 'USDT contract',
		Currencybasedcontract: 'Currency standard contract',
		SmartMartin: 'Smart Martin',
		AnnouncementonthelaunchofRainbowQuantitativeRobot: 'Announcement of the launch of  Quantitative Robot...',
		Invitefriendstoenjoya30bonus: 'Invite friends for 30% bonus rewards',
		sendinvitationTofriends: 'Send invitation',
		Friendscompleteregistrationandactivation: 'Regist and Activate Account',
		Getrewardsinproportion: 'Geting rewards',
		Myinvitationlink: 'Invitation Link',
		Myinvitationcode: 'Invitation code',
		Generateinvitationposter: 'My Poster',
		Facetofaceinvitation: 'Direct Initation',
		Invitationreward: 'Invitation reward',
		Quantify: 'Quantify',
	},
	xinzeng: {
		qianbao: "Wallet",
		faxian: "Find",
		xiaoxi: "News",
		wode: "Account",
		exchange_tt: "Swap",
		switch_pack: 'Swap pack',

		bangdingtibidizhi: "Please bind a withdrawal address",
		tibidizhi: "Withdrawal address",
		xuanzebi: "Select other currency",
		tibi: "Withdrawal",
		buzhichi: "Token type is not supported",
		fuzhichengg: "Copy successfully",
		chongbi: "Deposit",
		zichan: "Assets",
		shandui: "Flash Exchange",
		zhuanz: "Transfer",
		zanweikaif: "Not available temporarily",
		wodezichan: "My assets",
		keyongzichan: "Available assets",
		chankanxiangqing: "Details",
		chakangengduo: "More Information",
		zhuanzhang: "Transfer",
		zhiyawakuang: "Pledge Mining",
		zhengben: "View Statement",
		defipool: "DeFi Pool",
		shoukuan: "Amount received",
		chuangshixinbi: "New tokens",
		hongbao: "bonus",
		shengou: "Subscribe",
		zuixinhangqing: "Latest News",
		zanweikaitobng: "Not available temporarily",
		xi: "Once asset pledged to a virtual bank, a 2-week lock-up period will be applied before redemption"
	},
	tankuang: {
		no: "Cancel",
		yes: "Confirm"
	},
	login: {
		// ----20210409 start---- //
		welcome: '',
		userLawB: 'Login means confirm ',
		userLaw: '<User Agreement>',
		PleaseEnterRightPhoneNumber: 'Please Fill In Currect Phone Number',
		PleaseEnterRightVerificationCode: 'Please Fill In Currect SMS-Code',
		registAccount: 'Register',
		PleaseEnterYourPasswordAgain: 'Password Confirm',
		PleaseEnterYourCode: 'Invite Code',
		AgreeLaw: 'I agree',
		and: 'and',
		mianzetiaokuan: '<Exemption clause>',
		regist: 'Register',
		hasAccount: 'Registered?',
		// ----20210409 end---- //
		emailorphone: 'E-mail/Phone',
		VerificationCodeLogin: 'Verification code',
		PasswordToLogin: 'Password',
		PleaseEnterYourCellPhoneNumber: 'Please enter phone number',
		PleaseEnterVerificationCode: 'Please enter verification code',
		getCode: 'Obtain',
		login: 'login',
		login_false: 'Wrong user name or password',
		login_failed: 'The verification code is wrong',
		No_register: 'Please register first',
		WalletUsersLogInDirectly: 'Wallet users login',
		PleaseEnterYourMobilePhoneNumber: 'Please enter phone number/email address',
		PleaseEnterYourPassword: 'Please enter password',
		RegisteredAccountNumber: 'Register',//==========
		ForgetThePassword: 'Forget Password',
		PleaseInputMobilePhoneNumber: 'Please enter phone number',
		PleaseInputAPassword: 'Please enter password',
		PleaseEnterEheVerificationCode: 'Please enter the verification code',
		MobileRegistration: 'Register with phone',
		Login: 'Log in',
		Registeranaccount: 'Register new account',
		Pleaseenterverificationcode: 'Please enter verification code',
		'Login to agree': 'User Agreement',
		Obtain: 'Obtain',
		registered: 'registered',
		AlreadyhaveanaccountTologin: 'Already have an account? Log in',
		Topup200usdt: 'Top up 200 usdt  ',
		accountnumber: 'Account number',
	},
	common: {
		home: 'Home',
		personal: 'Account',
		lang: 'Language ',
		Chinese: '简体中文',
		English: 'English',
		loading: 'loading',
		affirm: 'Confirm',
		cancel: 'Cancel'
	},
	home: {
		MyAssets: 'My assets',
		redPacket: 'Red envelope',
		mining: 'Mining',
		blotting: 'Blotting',//=======不知道什么意思
		Ticket: 'Ticket',
		college: 'College',
		more: 'More',
		TPUSparkPlugInSAASMarketplace: 'TPU Spark plug-in SAAS market',
		ItCurrently: 'It is committed to providing platform application plug-ins for blockchain projects. Red envelope plug-in available Currently',
		PartnerRecruitment: 'Partner recruitment',
		Welcome: 'Welcome to join us as a technology, product, operations, and community partner to advance the entire SAAS platform'
	},
	liaotian: {
		RedEnvelopeHall: 'Red envelope hall',
		transferAccounts: 'Transfer',
		redPacket: 'Red envelope',
		orderToBetter: 'Grabbed',
		Bidding: 'Bidding',
		property: 'Property',
		GamesArePlayed: 'Games are played'//=============不知道什么意思
	},
	dig: {
		NervePledgesForMining: 'TPU pledge mining',
		MyPledge: 'My pledge',
		ThePledgeToRecord: 'Pledge record',
		ToPledge: 'To pledge',
		beFrom: 'From',
		greeting: 'Greetings',
		YouGet: 'You get',
		command: 'Command',
		RedEnvelope: 'send red envelopes',
		YouHaveOpenedTheRedEnvelope: 'Already opened',
		OpenRedEnvelope: 'Open a red envelope',
		TurnRedEnvelopes: 'Transfer red envelope',//==========存疑 不知道什么意思
		PleaseEnterRedEnvelope: 'Please enter red envelope code',
		altogether: 'There are ',
		individual: ' in total',
		HaveBeenReceiving: 'already claimed',
		snag: ' snag',
		gainRecording: 'gain recording',
		todayEarnings: 'Expected today\'s earnings',
		unit: 'Unit',
		phone: "Please set a phone number",
		password: "Please set a transaction password"

	},
	fahongbao: {
		SendingRedEnvelope: 'Send red envelope',
		NeedAPassword: 'Password required',
		need: 'Need',
		withNoNeed: 'Not need',
		SetThePassword: 'Set a password',
		ThroughTheType: 'Token type',
		BonusAmount: 'Amount of red envelope',
		pleaseEnter: 'Please enter',
		TheNumberOfRedPackets: 'Number of red envelopes',
		redEnvelopeType: 'Type of red envelope',
		random: 'Random',
		goHalves: 'Divide equally',
		CoverGreetings: 'Cover greetings',
		serviceCharge: 'Service charge',
		WhetherThePublic: 'Whether it is public',
		open: 'Open',
		privacy: 'Private',
		balance: 'Balance',
		NotEnvelope: 'Insufficient balance',
		Recharge: 'Deposit',
		GenerateRedEnvelope: 'Create red envelope',
		MyRecord: 'My Record',
		ContactUs: 'Contact us',
		PleaseEnterTradingPassword: 'Please fill in the password',
		SixPassword: 'Please fill in 6 digits',
		hint: 'Hint',
		InsufficientBalancePlease: 'Insufficient balance',
		PleaseEnterYourPassword: 'Please enter your password',
		PleaseSelectThePass: 'Please choose Token',
		totalAmountCannotBeEmpty: 'The total amount of the red envelope cannot be empty',
		numberOfRedEnvelopesCannotBeEmpty: 'The number of red envelopes cannot be empty',
		PleaseEnterYourUsernameAndPassword: 'Please enter username and password',
		affirm: 'Confirm',
		cancel: 'Cancel'
	},
	coffer: {
		MyAssets: 'My assets',
		cloudAssets: 'Cloud assets',
		availableCapital: 'Available assets',
		totalAssets: 'Total assets',
		frozenAssets: 'Frozen assets',
		withdraw: 'Withdraw',
		LocalAssets: 'Local assets',
		FunctionalIntegration: 'Function integrating'
	},
	explain: {
		GameShows: 'Explanation of Game rules',
		PasswordRedEnvelope: 'Password red envelope: user enters correct password to open a password envelope',
		OrdinaryHongbao: 'Ordinary red envelope: first-come-first-served',
		UnloggedUsers: 'Users not logged in need to enter phone number and verification code to receive a red envelope',
		RedEnvelopeIsALightweight: 'Red envelope is a lightweight IM application. More IM based interactive gameplay will be released in future.'
	},
	myPledge: {
		MyPledge: 'My Pledge',
		operate: 'Operate',
		AdditionalMortgage: 'Additional pledge',
		pledgeToRedeem: 'Pledge redemption',
		gainRecording: 'Profit',
		ExpectedEarnings: 'Expected today earnings',
		pledgeNumber: 'Pledge number',
		accumulatedEarnings: 'Accumulated earnings',
		pledgeOfDays: 'Pledge days',
		applicationDate: 'application date',
		Number: 'Number',
		currency: 'Currency',
		status: 'Status',
		packUp: 'Pack up',
		NoPledgeHasBeenCompleted: 'Expected pledge completion time is 24 hours',
		PleaseEnterTransactionPassword: 'Please enter transaction password',
		PleaseEnterAdditionalQuantity: 'Please enter additional quantity',
		SuccessfulPledgeRedemption: 'Redemption success',
		todayEarnings: 'Expected today earnings'
	},
	redemptionRecord: {
		pledgeToRecord: 'Pledge record',
		NoMore: 'No More',
		serialNumber: 'ID',
		operate: 'Operate',
		Number: 'Number',
		status: 'Status',
		IsThePledge: 'Pledge in progress',
		BeganToYield: 'Begin to yield',
		RedemptionComplete: 'Redemption complete',
		ApplicationDate: 'Application Date',
		ReturnsTheTime: 'Earnings time'
	},
	pledge: {
		DigTheNumber: 'Mining number',
		SinceThe50: 'Since',
		all: 'All',
		usable: 'available',
		HaveAlreadyPledged: 'Have already pledged',
		DueOnDemand: 'Due on demand',
		predict: 'Predict',
		StartCalculatingReturns: 'Start calculating earnings',
		ExpectedMonthlyEarnings: 'Expected annualized earnings',
		ToPledge: 'To pledge',
		PleaseEnterTransactionPassword: 'Please enter transaction password',
		pledgeSuccess: 'Pledge success'
	},
	track: {
		blotting: 'Blotting',
		BlockchainSourceTrackingCardStoragePlatform: 'Blockchain source tracking card storage platform',
		ProductDevelopmentAndDebuggingInProgress: 'Product development debugging in progress'
	},
	ticket: {
		ticketService: 'Ticket service',
		LetDigital: 'Enable digital currency support ticket purchases, such as train tickets, airplane tickets, phone CARDS, gas CARDS',
		SupportNULS: 'Support NULS, TPU, ETH, and other tokens',
		SpecialSupportForPerformance: 'Especially support on performance ticketing via quickly release and circulation of digital ticketing.We will communicate with the friends who have the scene of ticket demand for the performance',
		SupportForTrainTickets: 'Support for train tickets, air tickets, etc. In migration tests from older versions'
	},
	tpuschool: {
		BlockchainCorrelation: 'Blockchain correlation',
		learningMaterials: 'learning materials',
		caseTeaching: 'case teaching',
		solution: 'solution',
		InCollectingData: 'In collecting data'
	},
	more: {
		more: 'More',
		SupportTheSpread: 'Support the spread of authentic and effective voices',
		SupportThePromotion: 'Support the promotion of pioneering technologies and cultures'
	},
	geren: {
		change: "Switch language",
		personalCenter: 'Personal center',
		MyAssets: 'My assets',
		redEnvelopeToRecord: 'Red envelope to record',
		MentionFillingRecord: 'Mention filling record',
		gainRecording: 'Gain recording',
		personalSetting: 'Personal setting',
		InviteRewards: 'Invite rewards',
		IWantToFeedback: 'I want to feedback',
		switchAccount: 'Switch account',
		logOut: 'Log out',
		phoneNumber: 'Phone',
		email: 'Email',

		site: 'Site',
		cashFlow: 'Cash flow'
	},
	redRecode: {
		redRecode: 'Red envelope to record',
		ISent: 'I send',
		NoMore: 'No More',
		redPacket: 'Red packet',
		individual: 'Individual',
		get: 'Get',
		status: 'Status',
		InTheDistributed: 'in the distributed',
		HasRecycled: 'has recycled',
		time: 'Time',
		operation: 'Operation',
		examine: 'Examine',
		withdraw: 'Withdraw',
		IReceivedThe: 'I received',
		hint: 'Hint',
		WithdrawTheSuccess: 'Withdraw the success'
	},
	cashOut: {
		MentionFillingRecord: 'Mention filling record',
		NoMore: 'No more',
		serialNumber: 'ID',
		token: 'Token',
		number: 'Number',
		time: 'Time',
		WithdrawalRecord: 'Withdrawal record',
		status: 'Status',
		clickToView: 'Click to view'
	},
	shouyiRecord: {
		gainRecording: 'Gain recording',
		NoMore: 'No more',
		operation: 'Operation',
		serialNumber: 'ID',
		principal: 'Principal',
		rateInterest: 'RateInterest',
		earnings: 'Earnings',
		data: 'Data',
		GetBenefits: 'Receive income',
		GetTheSuccess: 'Get the success'
	},
	Uset: {
		set: 'Set',
		SetCellPhoneNumber: 'Set cell phone number',
		SetEmail: 'Set email address',
		loginPassword: 'Login password',
		transactionPassword: 'Transaction password',
		nicknameIsSet: 'Nickname is set',
		PleaseEnterNewNickname: 'Please enter new nickname',
		PictureUpload: 'Picture upload',
		affirm: 'Confirm',
		cancel: 'Cancel'
	},
	sPhone: {
		SetCellPhoneNumber: 'Set cell phone number',
		SetEmail: 'Set email address',
		phoneNumber: 'phone',
		email: 'Email',
		emailAuthenticationCode: 'Verification code',
		PleaseEnterYourCellPhoneNumber: 'Please enter phone number',
		messageAuthenticationCode: 'Verification code',
		pleaseMessageAuthenticationCode: 'Enter  verification code',
		getCode: 'Get code',
		YouNeed: 'You need to receive a verification code for withdrawals and transactions. Please set the correct phone number first',
		WrongCellPhoneNumber: 'Wrong cell phone number',
		verificationCode: 'The SMS verification code cannot be empty',
		phoneNumberHasBeenSetSuccessfully: 'mobile phone number has been set successfully'
	},
	sPwd: {
		SetTradePassword: 'Set login password',
		messageAuthenticationCode: 'Verification code',
		PleaseVerificationCode: 'Enter SMS verification code',
		getCode: 'Get code',
		newPassword: 'New password',
		pleaseNewPassword: 'Please enter a new password',
		affirmPassword: 'Affirm password',
		PleaseEnterPasswordAgain: 'Please enter the password again',
		setPassword: 'Set password',
		SMSVerificationCodeCannotBeEmpty: 'SMS verification code cannot be empty',
		pleaseAffirmPassword: 'Please affirm password',
		twoPasswordsDoNotMatch: 'Two passwords do not match',
		setPasswordSuccess: 'Set password success',
		email: 'Email',
		Forgotpassword: 'Forgot password?',
		phoneNumber: 'Phone'
	},
	sPay: {
		TradePasswordBinding: 'Trade password binding',
		messageAuthenticationCode: 'Verification code',
		PleaseSMSVerificationCode: 'Enter  verification code',
		getCode: 'Get code',
		TradePassword: 'Transaction password',
		pleaseEnterTradePassword: 'Please enter transaction password',
		ConfirmTransactionPassword: 'Confirm transaction password',
		pleaseAffirmTradePassword: 'Please confirm transaction password',
		BindingTradePassword: 'Binding trade password',
		PleaseEnterCorrectVerificationCode: 'Please enter correct verification code',
		PleaseEnterCorrectTransactionPassword: 'Please enter correct transaction password',
		PleaseEnterCorrectTransactionEmail: 'Please enter the correct mailbox',
		twoPasswordsDoNotMatch: 'Two passwords do not match',
		hint: 'Hint',
		TransactionPasswordChangedSuccessfully: 'Transaction password changed successfully',
		affirm: 'Confirm',
		email: 'Email',
		Pleaseenterthepassword: 'Please enter the password',
		Confirmpasswordagain: 'Confirm password again',


		phoneNumber: 'Phone'
	},
	team: {
		InviteRewards: 'Invite rewards',
		MyInvitation: 'My invitation',
		InviteLink: 'Invite link',
		InviteCode: 'Invite code',
		CopyInvitationAddress: 'Copy invitation address',
		CopyInvitationCode: 'Copy invitation Code',

		FaceFaceInvitation: 'Direct Invitation',
		SweepCodeToInvite: 'Sweep code to invite',
		MyReward: 'My reward',
		InviteNumber: 'Invite number',
		InviteReward: 'Invite reward',
		rankingList: 'Leaderboard',
		redPacket: 'Red packet',
		individual: 'Individual',
		time: 'Time',
		hint: 'Hint',
		affirm: 'Confirm',
		copySuccess: 'Copy success'
	},
	feedback: {
		coupleBack: 'Couple back',
		WelcomeCommunicateWithTheTeam: 'Welcome communicate with the team',
		PleaseEnterTheCommunicationItems: 'Please enter the communication items',
		ImmediatelySend: 'Immediately send',
		FeedbackContentCannotBeEmpty: 'Feedback content cannot be empty',
		FeedbackSuccess: 'Feedback success'
	},
	cashFlow: {
		cashFlow: 'Cash flow',
		NoMore: 'No more',
		serialNumber: 'ID',
		operate: 'Operate',
		flow: 'Flow',
		data: 'Data',
		GetTheSuccess: 'Get the success'
	},
	share: {
		laizi: "From",
		de: " Red envelope",
		zong: "Lump sum",
		chai: "Open red envelope",
		fas: "Send to friends",
		baocun: "Save as shared image",
		chang: "Long press the picture above and save it locally"
	},
	recharge: {
		one: "Coin address",
		min: "Minimum deposit",
		fuzhi: "Copy address"
	},
	tixian: {
		shenqing: "Withdrawal application",
		one: "Withdrawal token",
		two: "Quantity Available",
		dongjie: "Frozen quantity",
		duanxin: "SMS verification code",
		tixian: "Number of withdrawals",
		jiaoyi: "transaction password",
		fuwu: "Service fee selection",
		bangding: "Bind",
		fasong: "Send the verification code",
		fasongqingiqu: "Send request",
		qingone: "Enter SMS verification code",
		qingtwo: "Please enter the withdrawal amount",
		qingthree: "Please enter transaction password",
		tanone: "The withdrawal quantity is less than the minimum withdrawal quantity",
		tantwo: "The withdrawal quantity must be greater than zero",
		tanthree: "The withdrawal amount is greater than the available balance",
		tanfour: "withdraw",
		tanfive: "Please set the withdrawal address first",
		tansix: "The withdrawal is successful and will be processed within 24 hours",
		tansevw: "Please set transaction password first",
		zuidi: "Minimum quantity",
		keyi: "Withdrawable",
		fuwufei: "Service charge",
		shoudao: "Roger that:",
		yzmerr: "Verification code error",
		pwderr: "Wrong transaction password",
		tberr: "Failed to withdraw money"
	},
	bangdingdizhi: {
		one: "Bind address",
		two: "SMS verification code",
		three: "Please enter SMS verification code",
		huoquyanzhnegm: "Get verification code",
		jiaoyi: "Transaction password",
		qingshuru: "Please enter transaction password",
		tixian: "Withdrawal address",
		qingtwo: "Please enter the binding address",
		dizhi: "Address note",
		qingfour: "Please enter address remarks",
		bangdingdizhi: "Bind address",
		tanone: "SMS verification code cannot be empty",
		tantwo: "Please enter the address",
		tanthree: "Please enter address remarks",
		yes: "Bind successfully"
	},
	phoneCode: {
		A: ['Afghanistan +93+4', 'Albania +355+8', 'Algeria +213+12', 'American Samoa +684+16', 'Andorra +376+20', 'Angola +244+24', 'Anguilla +1264+660', 'Antigua and Barbuda +1268+28', 'Argentina +54+32', 'Armenia +374+51', 'Australia +61+36', 'Austria +43+40', 'Azerbaijan +994+31'],
		B: ['Bahamas +1242+44', 'Bahrain +973+48', 'Bangladesh +880+50', 'Barbados +1246+52', 'Belarus +375+112', 'Belgium +32+56', 'Belize +501+84', 'Benin +229+204', 'Bermuda Islands +1441+60', 'Bolivia +591+68', 'Botswana +267+72', 'Brazil +55+76', 'Brunei +673+96', 'Bulgaria +359+100', 'Burkina Faso +226+854', 'Burundi +257+108'],
		C: ['Cambodia +855+116', 'Cameroon +237+120', 'Canada +1+124', 'Cayman Islands +1345+136', 'Central African Republic +236+140', 'Chad +235+148', 'Chile +56+152', 'China +86+156', 'Colombia +57+170', 'Congo +242+178', 'Cook Islands +682+184', 'Costa Rica +506+188', 'Côte d\'Ivoire +225+384', 'Cuba +53+192', 'Cyprus +357+196', 'Czech Republic +420+203'],
		D: ['Denmark +45+208', 'Djibouti +253+262', 'Dominican Republic +1890+214'],
		E: ['Ecuador +593+218', 'Egypt +20+818', 'El Salvador +503+222', 'Estonia +372+233', 'Ethiopia +251+231'],
		F: ['Fiji Islands +679+242', 'Finland +358+246', 'France +33+250', 'French Guiana +594+254', 'French Polynesia +689+258'],
		G: ['Gabon +241+266', 'Gambia +220+270', 'Georgia +995+268', 'Germany +49+276', 'Ghana +233+288', 'Gibraltar +350+292', 'Greece +30+300', 'Grenada +1809+308', 'Guam +1671+316', 'Guatemala +502+320', 'Guinea +224+324', 'Guyana +592+328'],
		H: ['Haiti +509+332', 'Honduras +504+340', 'Hong Kong +852+344', 'Hungary +36+348'],
		I: ['Iceland +354+352', 'India +91+356', 'Indonesia +62+360', 'Iran +98+364', 'Iraq +964+368', 'Ireland +353+372', 'Israel +972+376', 'Italy +39+380'],
		J: ['Jamaica +1876+388', 'Japan +81+392', 'Jordan +962+400'],
		K: ['Kazakhstan +7+398', 'Kenya +254+404', "Korea, Democratic People's Republic of +850+408", 'Korea, Republic of +82+410', 'Kuwait +965+414', 'Kyrgyzstan +331+417'],
		L: ['Laos +856+418', 'Latvia +371+428', 'Lebanon +961+422', 'Lesotho +266+426', 'Liberia +231+430', 'Libya +218+434', 'Liechtenstein +423+438', 'Lithuania +370+440', 'Luxembourg +352+442', 'Rwanda +250+646'],
		M: ['Macau +853+446', 'Madagascar +261+450', 'Malawi +265+454', 'Malaysia +60+458', 'Maldives +960+462', 'Mali +223+466', 'Malta +356+470', 'Martinique +596+474', 'Mauritius +230+480', 'Mexico +52+484', 'Moldova +373+498', 'Monaco +377+492', 'Mongolia +976+496', 'Montserrat +1664+500', 'Morocco +212+504', 'Mozambique +258+508', 'Myanmar +95+104'],
		N: ['Namibia +264+516', 'Nauru +674+520', 'Nepal +977+524', 'Netherlands +31+528', 'Netherlands Antilles +599+530', 'New Zealand +64+554', 'Nicaragua +505+558', 'Niger +227+562', 'Nigeria +234+566', 'Norway +47+578'],
		O: ['Oman +968+512'],
		P: ['Pakistan +92+586', 'Panama +507+591', 'Papua New Guinea +675+598', 'Paraguay +595+600', 'Peru +51+604', 'Philippines +63+608', 'Poland +48+616', 'Portugal +351+620', 'Puerto Rico +1787+630'],
		Q: ['Qatar +974+634'],
		R: ['Reunion +262+638', 'Romania +40+642', 'Russia +7+643'],
		S: ['Saint Lucia +1758+662', 'Saint Vincent and the Grenadines +1784+670', 'Samoa +685+882', 'San Marino +378+674', 'Sao Tome and Principe +239+678', 'Saudi Arabia +966+682', 'Senegal +221+686', 'Seychelles +248+690', 'Sierra Leone +232+694', 'Singapore +65+702', 'Slovakia +421+703', 'Slovenia +386+705', 'Solomon Islands +677+90', 'Somalia +252+706', 'South Africa +27+710', 'Spain +34+724', 'Sri Lanka +94+144', 'Sultan +249+736', 'Suriname +597+740', 'Swaziland +268+748', 'Swedish +46+752', 'Swiss +41+756', 'Syria +963+760'],
		T: ['Taiwan +886+158', 'Tajikistan +992+762', 'Tanzania +255+834', 'Thailand +66+764', 'Togo +228+768', 'Tonga +676+776', 'Trinidad and Tobago +1809+780', 'Tunis +216+788', 'Turkey +90+792', 'Turkmenistan +993+795'],
		U: ['Uganda +256+800', 'Ukraine +380+804', 'United Arab Emirates +971+784', 'UK +44+826', 'United States +1+840', 'Uruguay +598+858', 'Uzbekistan +998+860'],
		V: ['Venezuela +58+862', 'Vietnam +84+704'],
		Y: ['Yemen +967+887'],
		Z: ['Zambia +260+894', 'Zimbabwe +263+716'],
	},
}
