let needRequiresAuth = true; // 是否需要检查 登录

export let yRoutes = [
	
    // 新建页面的的路由  这边写在一起了  等调试的时候 在一一区分
	
    // 9.assetssecondEdition  资产
    {
        path: "/assets2",
        name: "assets2",
        component: () => import("../views/newPage/assets2.vue"),
        meta: {
            // requiresAuth: needRequiresAuth // 需要登录
        }
    },
   
   
    // 15.apiAuthorization2  API授权第二版
    {
        path: "/apiAuthorization2",
        name: "apiAuthorization2",
        component: () => import("../views/newPage/apiAuthorization2.vue"),
        meta: {
            // requiresAuth: needRequiresAuth // 需要登录
        }
    },
	
    // 16.viewAPI2  查看 API
    {
        path: "/viewAPI2",
        name: "viewAPI2",
        component: () => import("../views/newPage/viewAPI2.vue"),
        meta: {
            // requiresAuth: needRequiresAuth // 需要登录
        }
    },
	
	
    // 20.rankingList2  排行榜
    {
        path: "/rankingList2",
        name: "rankingList2",
        component: () => import("../views/newPage/rankingList2.vue"),
        meta: {
            // requiresAuth: needRequiresAuth // 需要登录
        }
    },
    // 21.community2  社区
    {
        path: "/community2",
        name: "community2",
        component: () => import("../views/newPage/community2.vue"),
        meta: {
            // requiresAuth: needRequiresAuth // 需要登录
        }
    },
	


    // 登录成功预加载页面
    {
        path: "/",
        name: "newHome",
        component: () => import("../views/newHome/newHome.vue"),
        meta: {
            requiresAuth: needRequiresAuth // 需要登录
		}
    },


	
    // 日志
    {
        path: "/journal",
        name: "journal",
        component: () =>
            import(/* webpackChunkName: "home" */ "../views/journal/journal.vue"),
        meta: {
            requiresAuth: needRequiresAuth // 需要登录
        }
    },
    // 日志
    {
        path: "/bill",
        name: "bill",
        component: () =>
            import(/* webpackChunkName: "home" */ "../views/bill/bill.vue"),
        meta: {
            requiresAuth: needRequiresAuth // 需要登录
        }
    },
	{
	    path: "/bill_back",
	    name: "bill_back",
	    component: () =>
	        import(/* webpackChunkName: "home" */ "../views/bill/bill-back.vue"),
	    meta: {
	        requiresAuth: needRequiresAuth // 需要登录
	    }
	},
	
    // 咨询
    {
        path: "/consultingService",
        name: "consultingService",
        component: () =>
            import(/* webpackChunkName: "home" */ "../views/consultingService/consultingService.vue"),
        meta: {
            requiresAuth: needRequiresAuth // 需要登录
        }
    },

    // 咨询详情
    {
        path: "/viewArticle",
        name: "viewArticle",
        component: () =>
            import(/* webpackChunkName: "home" */ "../views/consultingService/article/viewArticle.vue"),
        meta: {
            requiresAuth: false // 需要登录
        }
    },
	
    // 提币页面
    {
        path: '/chargeMoney',
        name: "chargeMoney",
        component: () =>
            import(/* webpackChunkName: "home" */ "../views/chargeMoney/chargeMoney-back.vue"),
        meta: {
            requiresAuth: needRequiresAuth  // 需要登录才能进入到当前页面
        }
    },

    // quantification 量化
    {
        path: '/quantification',
        name: "quantification",
        component: () =>
            import(/* webpackChunkName: "home" */ "../views/quantification/quantification2.vue"),
        meta: {
            requiresAuth: needRequiresAuth  // 需要登录才能进入到当前页面
        }
    },

    // aboutOurs   关于我们
    {
        path: '/aboutOurs',
        name: "aboutOurs",
        component: () =>
            import(/* webpackChunkName: "home" */ "../views/user/aboutOurs/aboutOurs.vue"),
        meta: {
            requiresAuth: needRequiresAuth  // 需要登录才能进入到当前页面
        }
    },
	
	// aboutOurs   关于我们2
	{
	    path: '/aboutoursmessage',
	    name: "aboutoursmessage",
	    component: () =>
	        import(/* webpackChunkName: "home" */ "../views/user/aboutOurs/aboutoursmessage.vue"),
	    meta: {
	        requiresAuth: needRequiresAuth  // 需要登录才能进入到当前页面
	    }
	},


    // 购买激活码 purchaseActivationCode
    {
        path: '/robotSetup',
        name: "robotSetup",
        component: () =>
            import(/* webpackChunkName: "home" */ "../views/robotSetup/robotSetup.vue"),
        meta: {
            requiresAuth: needRequiresAuth  // 需要登录才能进入到当前页面
        }
    },
    {
        path: '/robotBatchSetup',
        name: "robotBatchSetup",
        component: () =>
            import(/* webpackChunkName: "home" */ "../views/robotBatchSetup/robotBatchSetup.vue"),
        meta: {
            requiresAuth: needRequiresAuth  // 需要登录才能进入到当前页面
        }
    },
    {
        path: '/robotSelectedSetup',
        name: "robotSelectedSetup",
        component: () =>
            import(/* webpackChunkName: "home" */ "../views/robotSelectedSetup/robotSelectedSetup.vue"),
        meta: {
            requiresAuth: needRequiresAuth  // 需要登录才能进入到当前页面
        }
    },
    // 分享 sharePage
    {
        path: '/sharePage',
        name: 'sharePage',
        component: () =>
            import("../views/sharePage/sharePage.vue"),
        meta: {
            requiresAuth: needRequiresAuth
        }
    },


    //我的激活码
    {
        path: '/memberClub',
        name: 'memberClub',
        component: () =>
            import("../views/newPage/memberClub.vue"),
        meta: {
            requiresAuth: needRequiresAuth
        }
    },

    
    {
        path: "/setLoginPwd",
        name: "setLoginPwd",
        component: () =>
            import("../views/newPage/setLoginPwd.vue"),
        meta: {
            requiresAuth: needRequiresAuth
        }
    },
    

    {
        path: "/withdrawMoney",
        name: "withdrawMoney",
        component: () =>
            import("../views/withdrawMoney/withdrawMoney-back.vue"),
        meta: {
            requiresAuth: needRequiresAuth
        }
    },

    // 币种 交易设置
    {
        path: "/transactionSetup",
        name: "transactionSetup",
        component: () =>
            import(/* webpackChunkName: "home" */ "../views/transactionSetup/transactionSetup.vue"),
        meta: {
            requiresAuth: needRequiresAuth // 需要登录
        }
    },

    // 币种 交易设置 版本 Yello transactionVersion2
    {
        path: "/transactionVersion2",
        name: "transactionVersion2",
        component: () =>
            import(/* webpackChunkName: "home" */ "../views/transactionSetup/transactionVersion2.vue"),
        meta: {
            // requiresAuth: needRequiresAuth // 需要登录
        }
    },

    // 资产Page
    {
        path: "/assets",
        name: "assets",
        component: () =>
            import(/* webpackChunkName: "home" */ "../views/newPage/assets2.vue"),
        meta: {
            requiresAuth: needRequiresAuth // 需要登录
        }
    },


    // 设置
    {
        path: "/setUp",
        name: "setUp",
        component: () =>
            import(/* webpackChunkName: "home" */ "../views/user/usersetUp/setUp.vue"),
        meta: {
            requiresAuth: needRequiresAuth // 需要登录
        }
    },

    // 用户注册  registerPage
    {
        path: "/registerPage",
        name: "registerPage",
        component: () =>
            import(/* webpackChunkName: "home" */ "../views/user/registerPage/registerPage.vue"),

        // 注册页面不需要 进行拦截
        // meta: {
        //     requiresAuth: needRequiresAuth // 需要登录
        // }
    },



    // 忘记密码  forgetThePassword
    {
        path: "/forgetThePassword",
        name: "forgetThePassword",
        component: () =>
            import(/* webpackChunkName: "home" */ "../views/user/forgetThePassword/forgetThePassword.vue"),
        meta: {
            // requiresAuth: needRequiresAuth // 需要登录
        }
    },

    //绑定手机号 bindingMobilePhoneNumber
    {
        path: "/bindingMobilePhoneNumber",
        name: "bindingMobilePhoneNumber",
        component: () =>
            import(/* webpackChunkName: "home" */ "../views/user/bindingMobilePhoneNumber/bindingMobilePhoneNumber.vue"),
        meta: {
            requiresAuth: needRequiresAuth // 需要登录
        }
    },
    // 修改昵称 changeNickname
    {
        path: "/changeNickname",
        name: "changeNickname",
        component: () =>
            import(/* webpackChunkName: "home" */ "../views/user/changeNickname/changeNickname.vue"),
        meta: {
            requiresAuth: needRequiresAuth // 需要登录
        }
    },
    // 登录密码 loginPassword
    {
        path: "/loginPassword",
        name: "loginPassword",
        component: () =>
            import(/* webpackChunkName: "home" */ "../views/user/loginPassword/loginPassword.vue"),
        meta: {
            requiresAuth: needRequiresAuth // 需要登录
        }
    },

    // 支付密码 paymentPassword
    {
        path: "/paymentPassword",
        name: "paymentPassword",
        component: () =>
            import(/* webpackChunkName: "home" */ "../views/user/paymentPassword/paymentPassword.vue"),
        meta: {
            requiresAuth: needRequiresAuth // 需要登录
        }
    },

    {
        path: "/transactionRecords",
        name: "transactionRecords",
        component: () =>
            import(/* webpackChunkName: "home" */ "../views/transactionRecords/transactionRecords.vue"),
        meta: {
            requiresAuth: needRequiresAuth // 需要登录
        }
    },



    {
        path: "/chongzhi",
        name: "chongzhi",
        component: () =>
            import(/* webpackChunkName: "home" */ "../views/chongzhi/chongzhi.vue"),
        meta: {
            requiresAuth: needRequiresAuth // 需要登录
        }
    },
    // 主页
    // 邀请奖励
    {
        path: "/team",
        name: "team",
        component: () =>
            import("../views/team/team.vue"),
        meta: {
            requiresAuth: needRequiresAuth // 需要登录
        }
    },
	
    // 转账
    {
        path: "/transfer",
        name: "transfer",
        component: () =>
            import(/* webpackChunkName: "transfer" */ "../views/transfer/transfer-back.vue"),
        meta: {
            requiresAuth: needRequiresAuth // 需要登录
        }
    },
    // 兑换
    {
        path: "/switch",
        name: "switch",
        component: () =>
            import(/* webpackChunkName: "switch" */ "../views/switch/switch.vue"),
        meta: {
            requiresAuth: needRequiresAuth // 需要登录
        }
    },






    // 反馈
    {
        path: "/feedback",
        name: "feedback",
        component: () =>
            import(/* webpackChunkName: "feedback" */ "../views/feedback/feedback.vue"),
        meta: {
            requiresAuth: needRequiresAuth // 需要登录
        }
    },

	
    {
        path: "/download",
        name: "download",
        component: () =>
            import(/* webpackChunkName: "explain" */ "../views/download/index.vue"),
        meta: {
            // requiresAuth: false // 需要登录
        }
    },
	
	// -----20200410 [start] -----
	// 钱包记录
	{
	    path: "/walletRecord",
	    name: "walletRecord",
	    component: () =>
	        import(/* webpackChunkName: "transfer" */ "../views/walletRecord/walletRecord.vue"),
	    meta: {
	        requiresAuth: needRequiresAuth // 需要登录
	    }
	},
	// 提币信息确认
	{
	    path: "/withdrawMoneyInfo",
	    name: "withdrawMoneyInfo",
	    component: () => import("../views/withdrawMoney/withdrawMoneyInfo.vue"),
	    meta: {
	        // requiresAuth: needRequiresAuth // 需要登录
	    }
	},
	// 安全验证
	{
	    path: "/checkVerifyCode",
	    name: "checkVerifyCode",
	    component: () =>
	        import(/* webpackChunkName: "home" */ "../views/withdrawMoney/checkVerifyCode.vue"),
	
	},
	// 安全中心
	{
	    path: "/securityCenter",
	    name: "securityCenter",
	    component: () =>
	        import(/* webpackChunkName: "home" */ "../views/newVersion/securityCenter.vue"),
	
	},
	// 工具
	{
	    path: "/userTool",
	    name: "userTool",
	    component: () =>
	        import(/* webpackChunkName: "home" */ "../views/newVersion/userTool.vue"),
	
	},
	// 托管
	{
	    path: "/trusteeship",
	    name: "trusteeship",
	    component: () =>
	        import(/* webpackChunkName: "home" */ "../views/newVersion/trusteeship.vue"),
	
	},
	
	{
	    path: "/checkTransferVerifyCode",
	    name: "checkTransferVerifyCode",
	    component: () =>
	        import(/* webpackChunkName: "home" */ "../views/transfer/checkTransferVerifyCode.vue"),
	
	},
	
	{
	    path: "/checkClubVerifyCode",
	    name: "checkClubVerifyCode",
	    component: () =>
	        import(/* webpackChunkName: "home" */ "../views/newPage/checkClubVerifyCode.vue"),
	
	},
	
	{
	    path: "/userLaw",
	    name: "userLaw",
	    component: () =>
	        import(/* webpackChunkName: "home" */ "../views/login/userLaw.vue"),
	
	},
	
	{
	    path: "/userLaw1",
	    name: "userLaw1",
	    component: () =>
	        import(/* webpackChunkName: "home" */ "../views/login/userLaw1.vue"),
	
	},
	
	{
	    path: "/sharePoster",
	    name: "sharePoster",
	    component: () =>
	        import(/* webpackChunkName: "home" */ "../views/sharePage/sharePoster.vue"),
	
	},
	
	
	// 7.defaultExchange  默认交易所
	{
	    path: "/defaultExchange",
	    name: "defaultExchange",
	    component: () => import("../views/newPage/defaultExchange.vue"),
	    meta: {
	        // requiresAuth: needRequiresAuth // 需要登录
	    }
	},
	
	// 3.settransactionPassword  设置交易密码
	{
	    path: "/setThePassword",
	    name: "setThePassword",
	    component: () => import("../views/newPage/setThePassword.vue"),
	    meta: {
	        // requiresAuth: needRequiresAuth // 需要登录
	    }
	},
	
	{
	    path: "/tobeVip",
	    name: "tobeVip",
	    component: () => import("../views/sharePage/tobeVip.vue"),
	    meta: {
	        // requiresAuth: needRequiresAuth // 需要登录
	    }
	},
	
	{
	    path: "/circularStrategy",
	    name: "circularStrategy",
	    component: () => import("../views/circularStrategy/circularStrategy2.vue"),
	    meta: {
	        // requiresAuth: needRequiresAuth // 需要登录
	    }
	},
	//新托管
	{
	    path: "/new_trusteeship",
	    name: "new_trusteeship",
	    component: () => import("../views/newPage/new_trusteeship.vue"),
	    meta: {
	        // requiresAuth: needRequiresAuth // 需要登录
	    }
	},
	//新托管详情页
	{
	    path: "/new_trusteeshio_msg",
	    name: "new_trusteeshio_msg",
	    component: () => import("../views/newPage/new_trusteeshio_msg.vue"),
	    meta: {
	        // requiresAuth: needRequiresAuth // 需要登录
	    }
	},
	//成为老师
	{
	    path: "/become_teacher",
	    name: "become_teacher",
	    component: () => import("../views/newPage/become_teacher.vue"),
	    meta: {
	        // requiresAuth: needRequiresAuth // 需要登录
	    }
	},
	//我是老师
	{
	    path: "/im_teacher",
	    name: "im_teacher",
	    component: () => import("../views/newPage/im_teacher.vue"),
	    meta: {
	        // requiresAuth: needRequiresAuth // 需要登录
	    }
	},
	//我的托管详情页
	{
	    path: "/my_trusteeshio_msg",
	    name: "my_trusteeshio_msg",
	    component: () => import("../views/newPage/my_trusteeshio_msg.vue"),
	    meta: {
	        // requiresAuth: needRequiresAuth // 需要登录
	    }
	},
	//我的托管详情页(已结束)
	{
	    path: "/my_trusteeshio2",
	    name: "my_trusteeshio2",
	    component: () => import("../views/newPage/my_trusteeshio2.vue"),
	    meta: {
	        // requiresAuth: needRequiresAuth // 需要登录
	    }
	},
	//托-量化详情
	{
	    path: "/lianghua",
	    name: "lianghua",
	    component: () => import("../views/newPage/lianghua.vue"),
	    meta: {
	        // requiresAuth: needRequiresAuth // 需要登录
	    }
	},
	//新托管2
	{
	    path: "/new_trusteeship2",
	    name: "new_trusteeship2",
	    component: () => import("../views/newPage/new_trusteeship2.vue"),
	    meta: {
	        // requiresAuth: needRequiresAuth // 需要登录
	    }
	},
	//绑定邮箱
	{
	    path: "/bindEmail",
	    name: "bindEmail",
	    component: () => import("../views/newPage/bindEmail.vue"),
	    meta: {
	        // requiresAuth: needRequiresAuth // 需要登录
	    }
	},
	// -----20200410 [end] -----
	
]
